<template>
  <div>
  <transition>
  <v-sheet
           :color="wsBACKGROUND"
           v-if="showLoginMenu" class="sideMenu pa-5" light
           :style="`width: ${displayHide(['xs','sm']) ? '400px' : '100%'};`"

  >

    <div class="d-flex  justify-space-between align-center pa-3 mb-10 mt-3">

        <img v-if="TEMPLATES_GET_PARAM('logo')"
            class="pl-3"  width="150px" alt="WeStudy" :src="TEMPLATES_GET_PARAM('logo')" />
        <img v-else class=""  width="220px" alt="WeStudy" src="@/assets/brand/logo_westudy_color.png" />

      <v-btn  icon plain @click="toggleLoginMenu">
          <v-icon>mdi-window-close</v-icon>
      </v-btn>
    </div>



    <authWindow />


  </v-sheet>
  </transition>

  <v-overlay z-index="1000" :value="showLoginMenu"  >
    <div class="stopScroll" @click="toggleLoginMenu"></div>
    </v-overlay>

  </div>
</template>

<script>
import authWindow from "@/components/auth/authWindow";
import { mapMutations, mapState} from 'vuex';

export default {
  name: "loginSideBar",
  components : {
    authWindow
  },
  computed : {
    ...mapState(['showLoginMenu']),
  },
  watch : {
    showLoginMenu() {
      this.FLUSH_LOADER()
    }
  },
  methods : {
    ...mapMutations('auth',['FLUSH_LOADER']),
    ...mapMutations(['toggleLoginMenu']),
  }
}
</script>

<style scoped>

.sideMenu {
  position:fixed;
  top:0;
  left:0;
  height: 100%;
  z-index: 999999000;
  transition: width 0.5s ease-out;
  overflow-y: auto;
}
.stopScroll {
  height: 100vh;
  width: 100% !important;
  top:0;
  left:0;
  position: fixed;

}


/*ANIMATION */
.v-enter {
  opacity: 0 !important;
  transform: translateX(-50px) ;
}
.v-enter-active {

  transition: all 0.2s ease-out;
}
.v-leave-active {
  transition: all 0.2s ease-out;
  opacity: 0 !important;
  transform: translateX(-50px) ;
}

</style>